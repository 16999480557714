@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f9fafc !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

/* Customize scroll bar */
* ::-webkit-scrollbar {
    width: 16px;
}

* ::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: rgba(96, 96, 96, 0.5);
}
* ::-webkit-scrollbar-thumb:hover {
    background-color: #606060;
}

/* React flow */
.process-map.react-flow .react-flow__node {
    cursor: default;
}
.process-map.react-flow .react-flow__controls {
    bottom: unset;
    left: unset;
    top: 8px;
    right: 8px;
}
a[href="https://reactflow.dev"]
{
    display: none;
}

.space-y-4 > :not(template) ~ :not(template) {
    padding-top: 1rem;
    margin-top: 1rem;
}
