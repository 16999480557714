.nav-active {
    svg {
        fill: #3699ff;
    }

    .MuiListItem-root {
        background-color: #1b1b28 !important;
    }

    .MuiTypography-root {
        color: white;
    }
}

.nav {
    .MuiTypography-root {
        color: rgba(255, 255, 255, 0.7);
    }

    svg {
        fill: rgba(255, 255, 255, 0.7);
    }

    .MuiListItem-root:hover {
        background-color: #1b1b28 !important;

        svg {
            fill: #3699ff;
        }
    }
}

.nav-root {
    .MuiListItem-root:hover {
        background-color: #1b1b28 !important;
        color: #fff !important;
    }

    .MuiListItem-root {
        .MuiListItemIcon-root {
            min-width: 45px;
        }
    }

    .MuiCollapse-root {
        .MuiListItemIcon-root {
            min-width: 35px;
        }
    }

    .MuiCollapse-root {
        .MuiListItem-root {
            padding-left: 30px;
        }
    }

    .nav-root {
        .MuiCollapse-root {
            .MuiListItem-root {
                padding-left: 45px;
            }
        }
    }
}

.ant-select-selector {
    border: none !important;
    outline: none !important;
}

.ant-select-outlined {
    border: none !important;
    outline: none !important;
}

.ant-select-selection-placeholder {
    left: 3px !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-size: 0.9rem !important;
}

.ant-select-selection-search {
    margin-inline-start: 0px !important;
}

.ant-select-arrow {
    inset-inline-end: 0px !important;
}

.ant-select-clear {
    inset-inline-end: 3px !important;
}

.viewer-layout-toolbar {
    display: none !important;
    grid-row: none !important;
}

.viewer-layout-main {
    margin-top: -45px !important;
    overflow: hidden !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.viewer-inner-page {
    padding: 0px !important;
}

.viewer-layout-container {
    border: none !important;
}
